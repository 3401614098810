var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
// import { ViewController } from 'ionic-angular';
import { Injector, OnInit } from '@angular/core';
import { MathUtils } from 'aigens-ng-core';
import { NavParams } from '@ionic/angular';
import { MobileBaseItemPage } from 'src/app/core/base/mobile-base-item-page';
/**
 * Generated class for the RecommendList page.
 *
 * See http://ionicframework.com/docs/components/#navigation for more info
 * on Ionic pages and navigation.
 */
var UnlockPopupDialog = /** @class */ (function (_super) {
    __extends(UnlockPopupDialog, _super);
    function UnlockPopupDialog(injector, navParams) {
        var _this = _super.call(this, injector) || this;
        _this.injector = injector;
        _this.navParams = navParams;
        // localStorageHelper: LocalStorageHelper;
        _this.animateQtyMap = {};
        _this.newTotal = 0;
        _this.addItems = []; // all single items in recommendation list
        _this.qtyTag = [];
        // pref: PreferenceService;
        _this.count = 0;
        _this.isGift = false; // deal with free items
        _this.showImage = true;
        _this.displayTitle = '';
        // this.localStorageHelper = this.injector.get(LocalStorageHelper);
        // this.pref = this.injector.get(PreferenceService);
        _this.recommendList = _this.navParams.get('recommendList');
        _this.order = _this.orderManager.order;
        _this.store = _this.navParams.get('store');
        _this.addItems = _this.navParams.get('items');
        _this.isGift = _this.navParams.get('isGift');
        _this.max = _this.navParams.get('max'); // for unlocks
        _this.recommendations = _this.navParams.get('recommendations'); // for AI recommendations
        if (_this.max) {
            _this.displayTitle = _this.t.instant('pages.item-select.chooseAtMost', { max: _this.max });
        }
        _this.init();
        return _this;
    }
    UnlockPopupDialog.prototype.init = function () {
        var _this = this;
        if (this.recommendations) {
            this.addItems = [];
            this.recommendations.forEach(function (rm) {
                _this.addItems.push(rm.item);
                _this.qtyTag.push(0);
                if (_this.showImage && (!rm.item.images || !rm.item.images['default']))
                    _this.showImage = false;
            });
            this.count = this.recommendations.length;
        }
        else {
            for (var i = 0; i < this.addItems.length; i++) {
                this.qtyTag[i] = 0;
                if (!this.addItems[i].parentId || this.addItems[i].parentId == this.addItems[i].id) {
                    this.count++;
                    if (this.showImage && (!this.addItems[i].images || !this.addItems[i].images['default']))
                        this.showImage = false;
                }
            }
            this.category = this.recommendList;
            this.itemgroups = this.recommendList.groups;
        }
    };
    UnlockPopupDialog.prototype.updateUI = function () {
    };
    UnlockPopupDialog.prototype.isVariationParent = function (i) {
        var item = this.addItems[i];
        var exist = this.isParent(item);
        if (exist) {
            var items = item.variations.slice(0);
            items.push(item);
            // this.presentDialog2(VariationDialog, { items: items, store: this.store, takeout: this.order.takeout }, (selected) => {
            //     if (selected) {
            //         let j: number;
            //         for (j = 0; j < this.addItems.length; j++) {
            //             if (this.addItems[j].id == selected.id) break;
            //         }
            //         this.onAddClicked(i, j);
            //     }
            // });
        }
        return exist;
    };
    UnlockPopupDialog.prototype.onOrderChanged = function (order) {
        // this.localStorageHelper.setOrder(order);
    };
    UnlockPopupDialog.prototype.isSet = function (category, item) {
        if (item.groups && item.groups.length > 0)
            return true;
        if (item.pgroup)
            return true;
        return category.groups.length > 1;
    };
    UnlockPopupDialog.prototype.isSoldout = function (item) {
        // let now = this.getNow();
        return !this.isParent(item) && (this.inventoryManager.isSoldout(item)); // || !this.menuManager.isAvailableCombo(this.store, this.category, item, this.order.takeout, now));
    };
    UnlockPopupDialog.prototype.isParent = function (item) {
        return item.variations != null && item.variations.length > 0;
    };
    // i: parent, j:selected
    UnlockPopupDialog.prototype.onAddClicked = function (i, j) {
        var _this = this;
        this.animateQtyMap['#' + this.addItems[i].id] = false;
        setTimeout(function () {
            _this.animateQtyMap['#' + _this.addItems[i].id] = true;
        }, 10);
        this.addItems[j].quantity++;
        this.qtyTag[i]++;
        this.newTotal += this.round(this.addItems[j].price);
    };
    UnlockPopupDialog.prototype.checkTotalQty = function () {
        var qty = 0;
        this.addItems.forEach(function (item) {
            qty += item.quantity;
        });
        console.log('selected qty:', qty);
        return qty;
    };
    UnlockPopupDialog.prototype.addClicked = function (i) {
        // this.playClick();
        if (this.max && this.checkTotalQty() == this.max) {
            if (this.max !== 1)
                return;
            // clear other selection if single select
            for (var index = 0; index < this.addItems.length; index++) {
                while (this.qtyTag[index] > 0)
                    this.minusItem(index);
            }
        }
        var item = this.addItems[i];
        if (this.checkTotalQty() == this.max)
            return;
        // deal with group items
        if (this.isVariationParent(i))
            return;
        // soldout
        if (this.isSoldout(item))
            return;
        // single item
        this.onAddClicked(i, i);
        console.log('add single item#' + i, this.addItems[i].name);
    };
    UnlockPopupDialog.prototype.removeVariation = function (i) {
        var _this = this;
        var item = this.addItems[i];
        var items = item.variations.slice(0);
        items.push(item);
        this.qtyTag[i] = 0;
        items.forEach(function (i) {
            for (var j = 0; j < _this.addItems.length; j++) {
                if (_this.addItems[j].id == i.id) {
                    _this.newTotal -= _this.round(_this.addItems[j].price * _this.addItems[j].quantity);
                    _this.addItems[j].quantity = 0;
                }
            }
        });
    };
    UnlockPopupDialog.prototype.minusItem = function (i) {
        if (this.isParent(this.addItems[i])) {
            this.removeVariation(i);
        }
        else {
            this.addItems[i].quantity--;
            this.qtyTag[i]--;
            this.newTotal -= this.round(this.addItems[i].price);
        }
    };
    UnlockPopupDialog.prototype.removeClicked = function (i) {
        if (this.qtyTag[i] <= 0)
            return;
        // this.playClick();
        console.log('remove clicked');
        this.minusItem(i);
    };
    UnlockPopupDialog.prototype.clearClicked = function () {
        // this.playClick();
        this.updateUI();
        this.modalController.dismiss('cancel');
    };
    UnlockPopupDialog.prototype.okClicked = function () {
        // this.playClick();
        if (this.round(this.newTotal) <= 0 && !this.isGift)
            return;
        /*
        this.presentDialog(ConfirmDialog, this.t.instant("pages.recommend-list.confirmTitle"), null, null, data => {

            if (data == "ok") {

                console.log('Buy clicked');
                let order: Order = this.orderManager.order;

                this.addItems.forEach(item =>{
                    for (let i = 0; i < item.quantity; i++) {
                        let now: number = this.getNow();
                        let group: ItemGroup = this.itemgroups[0];
                        let oi = this.orderManager.addSingleItem(this.category, group, item);
                    }
                });

                this.onOrderChanged(this.orderManager.order);

                this.updateUI();

                this.viewCtrl.dismiss("ok");
            } else {
                console.log('Cancel clicked');
                this.viewCtrl.dismiss(null);
            }
        });
        */
        console.log('Buy clicked');
        var order = this.orderManager.order;
        for (var index = 0; index < this.addItems.length; index++) {
            var item = this.addItems[index];
            for (var i = 0; i < item.quantity; i++) {
                var group = void 0;
                if (this.recommendations) {
                    group = this.recommendations[index].itemgroup;
                    this.category = this.recommendations[index].category;
                }
                else {
                    group = this.itemgroups[0];
                }
                var oi = this.orderManager.addSingleItem(this.category, group, item);
                if (this.isGift) {
                    if (!order.gifts)
                        order.gifts = [];
                    order.gifts.push(oi);
                }
            }
        }
        this.onOrderChanged(this.orderManager.order);
        this.updateUI();
        this.modalController.dismiss('ok');
    };
    UnlockPopupDialog.prototype.round = function (value) {
        return MathUtils.round(value, 2);
    };
    UnlockPopupDialog.prototype.getItemName = function (item) {
        var name = item.name;
        if (this.isParent(item)) {
            name = item.title;
        }
        if (!name || name.length == 0) {
            name = item.name;
        }
        return name;
    };
    return UnlockPopupDialog;
}(MobileBaseItemPage));
export { UnlockPopupDialog };
