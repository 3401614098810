import {NgModule} from '@angular/core';
import {PopupDialog} from './popup/popup';
import {FreeGiftDialog} from './free-gift/free-gift';
import {MemberPopupDialog} from './member-popup/member-popup';
import {ModifierDialog} from './modifier/modifier';
import {PasscodeDialog} from './passcode/passcode';
import {PreOrderDialog} from './pre-order/pre-order';
import {RewardListDialog} from './reward-list/reward-list';
import {SelectTablePage} from './select-table/select-table';
import {SurveyDialog} from './survey/survey';
import {TransactionsConfirm} from './transactions-confirm/transactions-confirm.component';
import {VariationDialog} from './variation/variation';
import {UnlockPopupDialog} from './unlockPopup/unlockPopup';
import {IonicModule} from '@ionic/angular';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {UnlockerModule} from '../shared/components/unlocker/unlocker.module';
import {IonicStorageModule} from '@ionic/storage';
import {HttpClient} from '@angular/common/http';
import {SecureVerifyModule} from './secure-verify/secure-verify.module';
import {TermsConditionsModule} from './terms-conditions/terms-conditions.module';
import {ConfigService} from '../core/services/config.service';
import {createTranslateLoader} from '../shared/utilities/createTranslateLoader';
import {ThemeService} from '../core/services/theme.service';
import {MultiStepComboModule} from '../routes/item/multi-step-combo/multi-step-combo.module';
import {OfferListDialog} from './offer-list/offer-list';

@NgModule({
    declarations: [
        PopupDialog,
        FreeGiftDialog,
        MemberPopupDialog,
        ModifierDialog,
        PasscodeDialog,
        PreOrderDialog,
        RewardListDialog,
        SelectTablePage,
        SurveyDialog,
        TransactionsConfirm,
        VariationDialog,
        UnlockPopupDialog,
        OfferListDialog
    ],
    entryComponents: [VariationDialog, PopupDialog,
        UnlockPopupDialog,
        FreeGiftDialog,
        MemberPopupDialog,
        ModifierDialog,
        PasscodeDialog,
        PreOrderDialog,
        RewardListDialog,
        SelectTablePage,
        SurveyDialog,
        TransactionsConfirm,
        OfferListDialog,
        VariationDialog],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        UnlockerModule,
        IonicStorageModule,
        SecureVerifyModule,
        TermsConditionsModule,
        MultiStepComboModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient, ConfigService, ThemeService]
            }
        }),
        IonicModule
    ],
    exports: [
        PopupDialog,
        FreeGiftDialog,
        MemberPopupDialog,
        ModifierDialog,
        PasscodeDialog,
        PreOrderDialog,
        RewardListDialog,
        SelectTablePage,
        SurveyDialog,
        TransactionsConfirm,
        VariationDialog,
        UnlockPopupDialog,
        OfferListDialog
    ]
})
export class DialogsModule {
}
