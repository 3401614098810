import {Component, Injector, OnInit} from '@angular/core';
import {Category, InventoryManager, Item, Store, OrderManager} from 'aigens-ng-core';
import {MobileBasePage} from '../../core/base/mobile-base-page';


@Component({
    selector: 'page-variation',
    templateUrl: 'variation.html',
    styleUrls: ['variation.scss']
})
export class VariationDialog extends MobileBasePage implements OnInit {

    store: Store;
    items: Item[] = [];
    title: string;
    selectedIndex = -1;
    category: Category;
    orderManager: OrderManager;
    inventoryManager: InventoryManager;
    constructor(public injector: Injector) {

        super(injector);

        this.category = this.getNavParams('category');
        this.items = this.getNavParams('items');
        this.store = this.getNavParams('store');
        this.inventoryManager = injector.get(InventoryManager);
        this.title = this.getNavParams('title');
        this.orderManager = this.getNavParams('orderManager');
        if (!this.title) { this.title = this.t.instant('pages.variation.title'); }
    }

    ngOnInit() {
        console.log('ngOnInit VariationPage', this.orderManager.getOrder());
    }


    dismissClicked() {
        this.modalController.dismiss(null);

    }


    isSoldout(item: Item): boolean {
        
        let now = this.convertNowDateToStoreTimeZone(this.store.timezone).getTime();
        const imSoldout = this.inventoryManager.isSoldout(item);
        // const mmSoldout = !this.menuManager.isAvailableCombo(this.store, this.category, item, this.orderManager.isTakeout(), now);
        let mmSoldout = !item.combo ? false : !this.menuManager.isAvailableCombo(this.store, this.category, item, this.orderManager.isTakeout(), now);

        return imSoldout || mmSoldout;

    }

    getModifierString(item: Item): string {
        const modifiers = '+';
        let groups = this.category.groups;

        if (item.groups && item.groups.length > 0) {
            groups = item.groups;

        } else {

            groups = groups.slice(1);


        }
        groups = groups.filter(group => group.items && group.items.length > 0);
        if (!groups || groups.length === 0) {
            return '';
        }

        return modifiers + groups.map(group =>  group.name).join('+');
    }

    itemClicked(i: number) {
        if (i === this.selectedIndex) {
            console.log('unselected item');
            this.selectedIndex = -1;
            return;
        }
        const item = this.items[i];

        console.log('selected item', item);

        if (this.isSoldout(item)) { return; }

        this.selectedIndex = i;

    }

    okClicked() {
        if (this.selectedIndex < 0) { return; }
        console.log('okClicked ', this.selectedIndex);
        this.modalController.dismiss(this.items[this.selectedIndex]);
    }

}
