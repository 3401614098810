<ion-header>
    <div tappable class="dismiss-btn" (click)="dismiss()">
        <ion-icon name="md-close" color="light-text"></ion-icon>
    </div>
</ion-header>
<ion-content>
    <div class="iframe-box">
        <iframe id="iframe" style="height:100%;width:100%" frameborder="0"></iframe>
    </div>
</ion-content>
<ion-footer>
    <ion-item *ngIf="needToAgreeMarketing" no-lines class="checkbox-box">
        <ion-checkbox color="success" [(ngModel)]="marketing"></ion-checkbox>
        <ion-label class="check-box-label">{{"pages.terms-conditions.agree-text"|translate : input}}</ion-label>
    </ion-item>
    <div text-center class="btn-box">
        <ion-button expand="block" color="success"
                    (click)="dismiss(true)">{{"pages.terms-conditions.confirm" | translate }}</ion-button>
    </div>
</ion-footer>
