/**
 *  this defines all pages and their correspond urls
 *  Please add '/' in the front of the url if it is one of root pages.
 *  This can avoid circular dependency error.
 */
export const RouteTable = {
    AlipayPaymentPage: 'alipay',
    StripeApplePaymentPage: 'apple',
    BlankPage: 'blank',
    ByodSummaryPage: 'byod-summary',
    BrandStoreListPage: 'brand-store-list',
    ByodBrandSplashPage: 'byod-brand-splash',
    ByodSplashPage: 'byod-splash',
    BrandFAQPage: 'brand-faq',
    CartPage: 'cart',
    ClosePage: 'close',
    CreditCardPaymentPage: 'credit-card',
    CodePage: 'code',
    CategoryListPage: 'category-list',
    CategoryItemListPage: 'category-item-list',
    CategoryGroupListPage: 'category-group-list',
    DirectByodSummaryPage: 'byod-summary/direct',
    DirectBrandStoreListPage: 'brand-store-list/direct',
    DirectCategoryItemListPage: 'category-item-list/direct',
    DirectMultiOrderHistoryPage: 'multi-order-history/direct',
    DirectOrderStatusPage: 'order-status/direct',
    DeliveryInformationPage: 'delivery-info',
    EftPaymentPage: 'eft',
    ItemGridPage: 'item-grid',
    ItemSelectPage: 'item-select',
    LoginPage: 'login',
    MemberRewardPage: 'member-reward',
    ModifierItemSelectPage: 'modifier-item-select',
    MultiOrderCartPage: 'multi-order-cart',
    MultiOrderHistoryPage: 'multi-order-history',
    MpgsHostSessionPage: 'host-session',
    OrderDetailPage: 'multi-order-history',
    MultiOrderDetailPage: 'multi-order-detail',
    OrderStatusPage: 'order-status',
    OrderStatusPreKioskPage: 'order-status-prekiosk',
    PopUpSetMenuPage: 'pop-up-set-menu',
    PopUpSetMenuWithImgPage: 'pop-up-set-Img-menu',
    ProfilePage: 'profile',
    PromotionDetailPage: 'promotion-detail',
    QrCodePage: 'qr-code',
    DineinOrTakeawayPage: 'dinein-takeaway',
    RegistrationPage: 'registration',
    ResetPasswordPage: 'reset-password',
    RewardListPage: 'reward-list',
    RedirectPayment: 'redirect-payment',
    SavedOrderListPage: 'saved-order-list',
    ScanQrCodePage: 'scan-qr-code',
    SearchItemPage: 'search-item',
    SecureVerifyPage: 'secure-verify',
    SetSubItemsPage: 'set-sub-items',
    SideMenuCategoryListPage: 'side-category-list',
    SummaryListPage: 'summary-list',
    StripeGooglePayPaymentPage: 'stripe-google',
    TermsConditionsPage: 'terms-conditions',
    TestPage: 'test',
    MultiStepComboPage: 'multi-step-combo',
    VerifyRegistrationPage: 'verify-registration',
    VerifyTableCodePage: 'verify-table-code',
    WechatOpenPaymentPage: 'wechat-open',
    OepayPaymentPage: 'oepay',
    OpenricePaymentPage: 'openrice',
    ValootWechatPayPaymentPage: 'valoot-wechat',
    MpgsApplePayPaymentPage: 'mpgs-apple',
    MPGSCreditCardPaymentPage: 'mpgs-credit-card',
    MpgsGooglePayPaymentPage: 'mpgs-google',
    WechatPayPaymentPage: 'wechat-pay',
    WirecardCreditCardV2: 'wirecard-v2'
};
