import {Category, Item, ItemGroup, ModifierBuilder, OrderItem, OrderItemBuilder, OrderManager, Store} from 'aigens-ng-core';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {NavParams, ToastController} from '@ionic/angular';
import {RouteTable} from '../../../core/route-table';
import {MobileBasePage} from '../../../core/base/mobile-base-page';
import {ModifierDialog} from '../../../dialogs/modifier/modifier';
import {ActivatedRoute} from '@angular/router';
import {ModifierItemSelectPageModal} from './../modifier-item-select-modal/modifier-item-select-modal';


@Component({
    selector: 'multi-step-combo-page',
    templateUrl: 'multi-step-combo-page.component.html',
    styleUrls: ['multi-step-combo-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiStepComboPage extends MobileBasePage implements OnInit, OnDestroy {

    // setting flags:
    isAutoPickup = false;
    preselectItem = false;

    // input params
    // main item category
    category: Category;
    store: Store;

    // helper fields
    builder: OrderItemBuilder;

    // static fields
    mainItemImage: string;

    currentOrderItem: OrderItem;
    // item groups
    groups: ItemGroup[];
    groupIdMap = {};
    // isGroupCompleted = {};
    builderGroupsCount = 0;
    mainItem: Item;
    itemMap = {};
    mainGroup: ItemGroup;
    total = 0;

    // the target edit item
    edit: OrderItem;

    modifierBuilder: ModifierBuilder;
    modifiable = false;
    missing = false;
    // hasSelectedModifiers: boolean;
    // selectedModifiersName: string;

    itemGroupMap = {};
    sameTitleItems = {};
    isTitleGroupExpand = {};
    openedTitleGroup = {};
    titles = {};
    // subgroup expand status
    isExpand: boolean[];

    isAutoPickupHasModifier = false;
    // TODO: make it a function?
    // auto pick groups have incomplete modifier?
    isAutoPickupModifierCompleted = false;
    // preselectItem
    orderMap: Map<string, string[]> = new Map();

    noNeedConfirm = {};

    // isAutoPickGroupFunc = this.isAutoPickGroup;
    screenWidthUnit = 0;
    dismissCallback: any;

    autoPickupGroups: ItemGroup[] = [];
    nonAutoPickupGroups: ItemGroup[] = [];
    modifierShown = false;

    constructor(injector: Injector, public modalNavParams: NavParams, private toastCtrl: ToastController, private cd: ChangeDetectorRef) {

        super(injector);
        // this.edit = this.getNavParams('edit');
        // this.store = this.getNavParams('store');

        console.log('toggle set menu page construction');
        this.store = this.modalNavParams.get('multi_store');
        this.edit = this.modalNavParams.get('multi_edit');
        this.dismissCallback = this.modalNavParams.get('multi_dismissCallback');


        if (this.store) {
            this.preselectItem = this.settings.preselectItem;
            this.isAutoPickup = this.settings.autoPickup;
        }
        let groups: ItemGroup[];
        let mainItem: Item;
        this.builder = new OrderItemBuilder(this.store);

        if (!this.edit) {
            // this.category = this.getNavParams('category');

            this.category = this.modalNavParams.get('multi_category');

            // category.groups
            // groups = this.getNavParams('groups');
            // mainItem = this.getNavParams('item');

            groups = this.modalNavParams.get('multi_groups');
            mainItem = this.modalNavParams.get('multi_item');

        } else {
            const origins = this.builder.findOrigins(this.edit);
            this.category = origins.category;
            groups = origins.groups;
            mainItem = origins.item;
        }

        this.builder.category = this.category;
        // this.isGroupCompleted = {};
        this.isExpand = [];


        // make deep copy
        // const [tmpItem, tmpGroups] = this.menuService.clone(mainItem, groups, new Date().getTime());
        // mainItem = tmpItem;
        // groups = tmpGroups;

        if (mainItem.groups && mainItem.groups.length > 0) {
            this.mainGroup = groups[0]; // buger页的所有数据
            groups = mainItem.groups; // 当前特定bugger的所有items Groups
            this.mainItem = mainItem;
            this.mainItem.quantity = 1;
        } else {

            // var choices = groups[0].choices;
            const min = groups[0].min;
            const max = groups[0].max;
            const choices = groups[0].choices;
            if ((max === 1 && min === 1) || choices === 1) {
                this.mainGroup = groups[0];
                groups = groups.slice(1);
                this.mainItem = mainItem;
                this.mainItem.quantity = 1;
            }
        }

        this.mainItemImage = this.mainItem.getImage('default', 200, false);
        if (this.store) {
            this.handleOrderManager();
        }
        console.log('111', groups);
        // DEEP CLONE!
        groups = groups && groups.filter(group => {
            console.log('111', this.store, this.category, group, this.orderManager.order.takeout, Date.now());
            return this.menuManager.isAvailableGroup(this.store, this.category, group, this.orderManager.order.takeout, Date.now(), false);
        });
        this.groups = groups;
        console.log(this.groups);
        // if (!this.edit) {
        this.builder.setItem(this.mainGroup, this.mainItem, 0);
        // }
        if (this.edit) {
            this.builder.transferSubitems(this.edit, this.groups);

            // build all no need subitem group

            for (let j = 0; j < this.groups.length; j++) {
                let matched = false;

                for (let i = 0; i < this.edit.groups.length; i++) {
                    // pass main item
                    if (i !== 0) {
                        if (this.groups[j].id === this.edit.groups[i].id) {
                            // console.log("matched and put", this.groups[j].name);
                            matched = true;
                            break;
                        }
                    }
                }

                if (!matched) {
                    // console.log('i am putting null clickedNoNeed: ', this.groups[j].name);
                    this.noNeedConfirm[this.groups[j].id] = true;
                    this.builder.clearItems(j + 1);
                    this.builder.build();
                }
            }
        }

        const orderItem: OrderItem = this.builder.build();

        this.isExpand[this.groups[0].id] = true;
        // this.isExpand[this.groups[0].id] = !this.preselectItem;

        this.modifierBuilder = new ModifierBuilder(this.store, orderItem);

        if (this.edit) {
            this.modifierBuilder.transfer(this.edit);
            this.modifierBuilder.build();
        }
        this.flatten();
        this.total = this.mainItem.price;


        console.log(this.groups);

        this.screenWidthUnit = this.platform.width() / 100;
        if (this.screenWidthUnit > 4.75) {
            /// Largest screen with : 475px
            this.screenWidthUnit = 4.75;
        }

    }

    checkDifference(index, input) {
        return input['id'];
    }

    ionViewWillEnter() {
        super.ionViewWillEnter();

        // console.log('group', this.groups);
    }

    handleOrderManager() {
        if (this.multOrderManager.enableMutlOrder) {
            this.settingMultOrderManager();
        } /*else if (this.orderManager.isEmpty()) {
            this.orderManager.createOrder(this.store.id, null, null, null, this.store);
        }
        this.orderManager.settingOrderType(this.orderManager.mode);*/
    }

    ngOnInit() {
        super.ngOnInit();
        console.log('on init');
        this.handleModifier();
        this.checkAutoPickupHasModifier();
        this.checkAutoPickupModifierCompleted();
        this.cd.detectChanges();
        for (const key in this.isExpand) {
            if (this.isExpand[key]) {
                this.preOpenTitleGroup(key, -1);
            }
        }
    }

    ngOnDestroy() {
        // this.groups.forEach((group) => {
        //         group.items.forEach((item) => {
        //                 item['groupIndex'] = null;
        //         });
        // });
    }

    // TODO: merge this with flatten

    // check if mainitems/auto pick up items has modifiers, should add pre-selected ones
    checkAutoPickupHasModifier(): void {

        this.isAutoPickupHasModifier = false;
        // fist check if main item is selected and check itself has modiifer or not
        if (this.builder.groups && this.builder.groups[0] && this.mainItem) {
            if (this.builder.groups[0].items[0] && this.builder.groups[0].items[0].id === this.mainItem.id) {
                console.log('this.builder.groups[0].itemGroupMap[0].id', this.builder.groups[0].items[0].id);

                for (const mgroup of this.mainItem.mgroups) {
                    if (mgroup) {
                        this.isAutoPickupHasModifier = true;
                        break;
                    }
                }
            }
        }

        if (this.isAutoPickupHasModifier) {
            return;
        }

        let mgroups = this.mainItem && this.mainItem.mgroups || [];
        this.isAutoPickupHasModifier = mgroups.length > 0;

        // second check if autopick group need modifier or not
        for (let i = 0; i < this.groups.length; i++) {
            if (this.isAutoPickGroup(this.groups[i])) {
                if (this.hasRequiredModifier(this.groups[i].items)) {
                    this.isAutoPickupHasModifier = true;
                    return;
                }
            }
        }
        console.log('this.isExpand', this.isExpand);
    }

    dismissModal() {
        // canActivate(
        //     next: ActivatedRouteSnapshot,
        //     state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        //     console.log('item guard');
        //     if ((this.navigationService.lastParams && (this.navigationService.lastParams['edit'] || this.navigationService.lastParams['item'])) || this.navigationService.poping) {
        //         return true;
        //     } else {
        //         // this.navController.pop().then(data => {
        //         //     console.log('pop ok', data);
        //         // }, error => {
        //         //     console.log('cannot pop', error);
        //         const t = this.navigationService.getRootPageUrl();
        //         console.log('home guard', t);

        //         if (t) {
        //             // TODO: not allowed to enter target root. go to parent route?
        //             this.navController.setDirection('root');
        //             this.router.navigate([this.navigationService.getRootPageUrl()]);
        //         }

        //     }

        //     return false;
        // }
        this.modalController.dismiss();
    }
    backButtonClick(toRoot?: boolean, route?: ActivatedRoute) {
        // if the outlet has page stack, pop
        // else redirect to root page
        console.log('on back button click');
        console.log('is navigating?', this.navigationService.navigating);

        if (!toRoot) {
            console.log('back by pop');
            this.googleAnalyticEventTrack('back');
            // if (this.backByLocation) {
            //     this.location.back();
            //     window.onpopstate
            // window.history.replaceState({},this.location.path());
            // } else {
            if (!this.navigationService.poping) {
                this.navigationService.lastParams = null;
                console.log('pop start');
                this.navigationService.popPage();
            } else {
                console.log('pop is in progress, try again later');
            }


            // }
        } else {
            this.googleAnalyticEventTrack('back');
            console.log('back by navigate to root');
            this.navigationService.popToRoot();
        }
        // this.stackController.pop(0)
        // this.navController.goBack();
        // this.navController.pop();
    }

    // TODO: merge with the function above
    checkAutoPickupModifierCompleted(): any {
        console.log('checking isAutoPickupmodifiercompleted');
        let completed = true;

        console.log('this.builder.groups', this.builder.groups);
        console.log('this.mainItem', this.mainItem);
        // fist check if main item is selected and check itself has modiifer or not
        if (this.builder.groups && this.builder.groups[0] && this.mainItem) {
            if (this.builder.groups[0].items[0] && this.builder.groups[0].items[0].id === this.mainItem.id) {

                for (const mgroup of this.mainItem.mgroups) {
                    if (mgroup.min > 0) {

                        const mgroups: ItemGroup[] = this.builder.groups[0].items[0].mgroups;

                        if (mgroups) {
                            for (let i = 0; i < mgroups.length; i++) {
                                let quantities = 0;
                                for (const tmpItem of mgroups[i].items) {
                                    quantities += 1 * tmpItem.quantity;
                                }

                                if (mgroups[i].min > 0) {
                                    completed = quantities >= mgroups[i].min;
                                    if (!completed) {
                                        return;
                                    }
                                }
                            }
                        } else {
                            completed = false;
                        }
                    }
                }
            }
        }

        for (let i = 0; i < this.mainItem.groups.length; i++) {
            console.log('i', i);
            // check autopickup group with mainItem to see if it needs modifier
            if (this.isAutoPickGroup(this.mainItem.groups[i])) {
                console.log('mainitem.group', this.mainItem.groups[i]);
                for (const item of this.mainItem.groups[i].items) {
                    if (item.mgroups.length > 0) {
                        console.log('this.builder.groups', this.builder.groups);
                        console.log('this.builder.groups[i]', this.builder.groups[i + 1]);
                        let isItemMgroupMinGreaterThanZero = false;

                        if (!this.builder.groups[i + 1] || !this.builder.groups[i + 1].items || !this.builder.groups[i + 1].items[0] || !this.builder.groups[i + 1].items[0].mgroups) {
                            for (const itemMgroup of item.mgroups) {
                                if (itemMgroup.min > 0) {
                                    isItemMgroupMinGreaterThanZero = true;
                                    break;
                                }
                            }
                            if (isItemMgroupMinGreaterThanZero) {
                                completed = false;
                                return;
                            } else {
                                if (completed) {
                                    this.isAutoPickupModifierCompleted = true;
                                    return;
                                }
                            }
                        }

                        for (let j = 0; j < this.builder.groups[i + 1].items[0].mgroups.length; j++) {
                            let quantities = 0;
                            for (const tmpItem of this.builder.groups[i + 1].items[0].mgroups[j].items) {
                                quantities += 1 * tmpItem.quantity;
                            }

                            if (item.mgroups[0].min > 0) {
                                completed = quantities >= item.mgroups[0].min;

                                if (!completed) {
                                    return;
                                }
                            }
                        }
                    } else {
                        completed = true;
                    }
                }
            }
        }

        if (completed) {
            this.isAutoPickupModifierCompleted = true;
        }
        console.log('isAutoPickupModifierCompleted', this.isAutoPickupModifierCompleted);

    }

    toggleGroupExpansion(groupId: string) {
        console.log('toggleGroupExpansion:', groupId);
        if (!this.isExpand[groupId]) {
            this.isExpand[groupId] = true; // 展开
            setTimeout(() => {
                this.preOpenTitleGroup(groupId, -1);
            }, 100);
        } else {
            this.isExpand[groupId] = false; // 收缩
            this.openedTitleGroup[groupId] = null;
            for (const key in this.isTitleGroupExpand[groupId]) {
                this.isTitleGroupExpand[groupId][key] = false;
            }
        }
        this.cd.detectChanges();

    }

    async modifierClicked() {
        if (this.modifierShown)
            return;
        const ois = this.builder.build();
        console.log('modifierClicked');

        let params = {store: this.store, orderitem: ois, edit: true};
        // window.history.pushState({}, 'Order.Place', window.location.href);
        const popup = await this.modalController.create({component: ModifierItemSelectPageModal, componentProps: params});
        popup.onDidDismiss().then(data => {
            this.modifierShown = false;
            this.checkAutoPickupModifierCompleted();
            this.cd.detectChanges();
        });
        popup.present().then(data => {
            this.modifierShown = true;
        });
    }

    preOpenTitleGroup(groupId: string, overwrite?: 1 | -1) {
        console.log('preOpenTitleGroup');
        /*const tmp = this.hasItemTitleGroup[groupId];
        if (tmp) {
            this.isTitleGroupExpand[groupId][tmp.title] = false;
            this.titleGroupClicked(tmp.title, tmp.index, groupId, isOpeningGroupCell);

        } else*/
        if (this.titles[groupId] && this.titles[groupId].length > 0) {
            for (let i = 0; i < this.titles[groupId].length; i++) {
                this.titleGroupClicked(this.titles[groupId][i], i, groupId, overwrite);
            }
        }
    }

    // noNeed
    clickedNoNeed(group: ItemGroup, index: any) {
        if (this.noNeedConfirm[group.id]) {
            return;
        }
        const groupIndex = this.groups.indexOf(group) + 1;

        console.log('group clickedNoNeed: ', groupIndex, group);
        console.log('this.builder', this.builder.groups[groupIndex]);

        let quantities = null;
        if (this.builder.groups[groupIndex] && this.builder.groups[groupIndex].items && this.builder.groups[groupIndex].items.length) {
            quantities = 0;
            // quantities = quantities + this.builder.getQuantity
            for (const item of this.builder.groups[groupIndex].items) {
                quantities = quantities + this.builder.getQuantityInGroup(groupIndex, item.id);
            }
            console.log('check quantities', quantities);
        }

        this.noNeedConfirm[group.id] = true;
        this.builder.clearItems(groupIndex);
        this.builder.build();
        this.isExpand[group.id] = false;

        // console.log("this.displayGroups[index-1]", this.displayGroups[index-1]);


        // for checking next group
        // TODO: go to next incomplete group
        const itemGroup = this.groups[index + 1];
        console.log('checking next itemGrp', this.groups, index + 1);
        if (itemGroup) {
            this.isExpand[itemGroup.id] = true;
        }

        // this.checkingGroupCompleted(groupIndex);
        this.updateSelectedModifier();
        // this.next();


    }

    addToCartClicked() {

        this.googleAnalyticEventTrack('add to cart');
        console.log(this.groups);
        console.log('isCompletedisCompleted', this.isCompleted());
        console.log('isAutoPickupModifierCompletedisAutoPickupModifierCompleted', this.isAutoPickupModifierCompleted);
        if (!this.isCompleted() || !this.isAutoPickupModifierCompleted) {
            // let checked = false;
            this.missing = true;
            for (let i = 0; i < this.groups.length; i++) {
                this.isExpand[this.groups[i].id] = !this.isGroupCompleted(this.groups[i]);
            }
            // TODO: scroll to the first incomplete group
            this.showNotCompletedAlert();
            return;
        }
        this.missing = false;

        const oi: OrderItem = this.builder.build();
        oi.combo = true;

        let orderManager;
        if (this.multOrderManager.enableMutlOrder) {
            orderManager = this.multOrderManager.getOrderManager(this.store.id);
        } else {
            orderManager = this.orderManager;
        }

        // const handler = this.getNavParams('handler');

        const handler = this.modalNavParams.get('multi_handler');

        if (!handler) {
            if (this.edit) {
                // this.orderService.replaceOrderItem(this.edit, this.category, igs);
                orderManager.replaceOrderItem(this.edit, oi);
                this.dismissCallback && this.dismissCallback();
            } else {
                // this.orderService.addSet(this.category, igs);
                orderManager.addOrderItem(oi);
            }

        }


        if (orderManager.store.menu && orderManager.store.menu.quickCheckout || handler) {
            this.modalController.dismiss().then(() => {
            // this.pushByUrl(this.multOrderManager.enableMutlOrder ? RouteTable['MultiOrderCartPage'] : RouteTable['CartPage'], {}, {replaceUrl: true}).then(
            this.pushByUrl(this.multOrderManager.enableMutlOrder ? RouteTable['MultiOrderCartPage'] : RouteTable['CartPage'], {}).then(
                () => {
                    // find index of home page, and remove from home page
                    let page: any;
                    const catLayout = this.settings.catLayout;

                    /*if (this.orderService.isBK) {
                        page = CategoryItemListPage;
                    } else {
                        let noneBKPage: any;
                        if (catLayout === 'showAll') {
                            noneBKPage = CategoryItemListPage;

                        } else if (!catLayout || catLayout === 'none') {

                            noneBKPage = ItemGridPage;

                        } else {

                            noneBKPage = CategoryListPage;
                        }

                        // page = this.edit ? (this.multOrderManager.enableMutlOrder ? BrandStoreListPage : noneBKPage) : noneBKPage;
                    }*/

                    // TODO: remove history
                    // const index: number = this.navController.getViews().findIndex(
                    //     view => (view.instance instanceof (page))
                    // );
                    // const current: number = this.navController.getActive().index;
                    // console.log('views:' + this.navController.getViews()[0]);
                    // console.log('index:' + index);
                    // console.log('current:' + current);
                    // this.navController.remove(index + 1, (current - 1) - index);

                    if (handler) {
                        handler(oi);
                    }

                }
            );
            });
        } else {
            this.modalController.dismiss();
            // this.navigationService.popPage();

        }


    }

    isAutoPickGroup(group: ItemGroup, items?: Item[]): boolean {
        if (items) {
            return group.min === group.max && items.length === group.min;
        } else {
            const availableItems = group.items;
            return group.min === group.max && availableItems.length === group.min;
        }
    }

    // new thing
    flatten() {
        let i = 0;
        // let y = 0;
        console.log(this.groups);
        for (const group of this.groups) {
            const items = [];
            // get all available items of the given group
            const mainItems = this.menuManager.getAvailableItems(this.store, this.category, group, this.orderManager.isTakeout());
            group.items = mainItems;
            // let isSet = false;
            // init status for title group
            this.isTitleGroupExpand[group.id] = {};
            // make a copy of itemGroupMap with some special value?
            let modifiedItems: Item[] = [];
            const isAutoPickupGroup = this.isAutoPickup && this.isAutoPickGroup(group, mainItems);
            if (isAutoPickupGroup) {
                this.autoPickupGroups.push(group);
            } else {
                this.nonAutoPickupGroups.push(group);
            }
            if (mainItems && mainItems.length > 0) {
                mainItems.forEach((item, index) => {
                    // item['groupIndex'] = i + 1;
                    // for variations?
                    /* if (item.variations && item.variations.length > 0) {
                             console.log('wtf is variations');
                             item.variations = item.variations.filter((vartion) => {
                                     vartion['groupIndex'] = item['groupIndex'];
                                     if (this.itemMap[vartion.id]) {
                                             return false;
                                     } else {
                                             this.itemMap[vartion.id] = vartion;
                                             return true;

                                     }
                             });

                     }
                     */
                    // put all itemGroupMap in itemMap
                    this.itemMap[item.id] = item;

                    // if not edit mode and should do auto pickup
                    if (isAutoPickupGroup && !this.edit) {
                        console.log('auto pick group', group);
                        this.addClicked(item, group, false, true);
                        // isSet = true;
                    }

                });

                // handle drinks
                modifiedItems = this.groupingTitle(group, mainItems);
                // if (this.edit) {
                //         if (!(group.min === group.max && mainItems.length === group.min && this.isAutoPickup) || !this.isAutoPickup) {
                //                 group['isAutopickUp'] = true; // show item
                //         } else {
                //         }
                // } else {
                // if (!this.isGroupCompleted(group) || !this.isAutoPickup) {
                // } else if (group.optional === false) {
                //         // 因為auto pickup 出錯，特定條件下會把item group一些 un-available itemGroupMap 都pickup 起來,应该忽略optional === true 的 item group
                //
                // }
                // }
                items.push(...modifiedItems);
            }


            if (this.preselectItem && !this.edit) {


                let preselectItem = null;

                // if (this.store.pos.type !== "eats365") preselectItem = mainItems[0];


                if (group.defaultId && this.itemMap[group.defaultId]) {
                    preselectItem = this.itemMap[group.defaultId];
                    if (preselectItem) {
                        // if (!this.isSoldout(preselectItem[i], this.category)) {
                        if (!this.isSoldout(preselectItem, this.category)) {
                            this.addClicked(preselectItem, group, false, true);
                            this.addOrderItemMap(group, preselectItem[0]);
                        }
                    }
                } else if ((!(group.min === 0) && !group.optional)) {
                    // if there is no default item, it is not optional and group min > 0
                    // do not pick

                    // preselectItem = mainItems[0];
                    // if (preselectItem) {
                    //     this.addClicked(preselectItem, false);
                    // }
                } else {
                    // pick no need
                    // let groupIndex = this.groups.indexOf(group) + 1;

                    // console.log('#group clickedNoNeed: ', groupIndex, group);
                    // this.builder.clearItems(groupIndex);
                    // this.builder.build();
                    // this.checkingGroupCompleted(groupIndex);
                }

            }

            this.itemGroupMap[group.id] = items;
            if (this.sameTitleItems[group.id]) {
                for (const key in this.sameTitleItems[group.id]) {
                    const index = this.titles[group.id].indexOf(key);
                    this.isTitleGroupExpand[group.id][key] = false;
                    // this.titleGroupClicked(key, index, group.id);
                }
                // for (const key in this.isTitleGroupExpand[group.id]) {
                //     console.log('loop title expand?', this.isTitleGroupExpand);
                //     if (this.isTitleGroupExpand[group.id][key]) {
                //         const index = this.titles[group.id].indexOf(key);
                //         this.hasItemTitleGroup[group.id] = {title: key, index: index};
                //         this.titleGroupClicked(key, index, group.id);
                //     }
                // }
                // init sub item groups

            }
            if (!this.isGroupCompleted(group) || !this.isNoNeedSelected(group)) {
                this.isExpand[group.id] = true;
            }
            i++;
        }
        console.log(this.itemGroupMap);

    }

    addOrderItemMap(group: ItemGroup, item: Item) {
        if (!group || !item) {
            return;
        }
        if (!this.orderMap.has(group.id)) {
            this.orderMap.set(group.id, []);
        }
        this.orderMap.get(group.id).push(item.name);
        this.updateOrderMap(group.id);

    }

    updateOrderMap(id: string) {
        let newOrderArr = Object.assign([], this.orderMap.get(id));
        this.orderMap.set(id, newOrderArr);
    }

    groupingTitle(group: ItemGroup, items: Item[]): Item[] {
        const tmp = [];
        this.sameTitleItems[group.id] = {};
        this.titles[group.id] = [];

        for (const item of items) {
            if (item.variations && item.variations.length > 0) {
                this.titles[group.id].push(item.title);
                this.sameTitleItems[group.id][item.title] = [item].concat(item.variations);
            } else if (item.group) {
                const title = item.group;
                // if any items has sub group field
                if (this.titles[group.id].findIndex(t => title === t) === -1) {
                    // save sub group title
                    this.titles[group.id].push(title);
                }
                // push item to sub item groups under groupId.title
                if (!this.sameTitleItems[group.id][title]) {
                    this.sameTitleItems[group.id][title] = [item];
                } else {
                    this.sameTitleItems[group.id][title].push(item);
                }
                const index = tmp.findIndex(tmpItem => tmpItem.id === item.id);
                if (index !== -1) {
                    tmp.splice(index, 1);
                }
            } else {
                tmp.push(item);
            }
        }


        return tmp;

    }

    getQuantity(item: Item, group: ItemGroup): number {
        const qty: number = this.builder.getQuantityInGroup(this.getGroupIndex(group), item.id);
        return qty;
    }

    has(item: Item, group: ItemGroup): boolean {
        return this.getQuantity(item, group) > 0;
    }

    add(item: Item, group: ItemGroup, isPreselect: boolean = false) {
        let oi;
        let groupIndex = this.getGroupIndex(group);
        if (groupIndex === -1) {
            groupIndex = this.groups.findIndex(g => {
                // TODO: is compared by id safe?
                return (g !== null && g !== undefined && g.id === group.id);
            }) + 1;
        }

        const isOptional = group.min === 0;
        if (this.getGroupMode(group) === 'single') {
            const max = group.max;
            let able = true;
            if (max >= 1) {
                const count: number = this.totalQuantity(groupIndex);

                if (count >= max && max !== 1) {
                    able = false;
                }
            }

            if (max === 1 && !this.has(item, group)) {

                this.builder.clearItems(groupIndex);

            }

            if (able && !this.has(item, group)) {

                this.builder.plusItem(group, item, groupIndex);
                oi = this.builder.build();
            } else if (this.has(item, group)) {
                this.discard(item, group);
            }

            if (!isPreselect) {
                if (this.isGroupCompleted(group)) {

                    this.handlingModifier(oi, group, this.itemMap, () => {
                        // if (this.groups[index]) {
                        this.toggleGroupExpansion(group.id);
                        // console.log('close' + group.id);
                        // if next incomplete group exists, expand it
                        for (let i = groupIndex; i < this.groups.length; i++) {
                            if (this.groups[i].id === group.id && this.isGroupCompleted(this.groups[i])) {
                                this.toggleGroupExpansion(this.groups[groupIndex].id);
                                break;
                            }
                        }
                        // if (group && this.isGroupCompleted(group)) { //？？？
                        //     this.toggleGroupExpansion(group.id);
                        // }


                        // ???
                        //         this.toggleGroupExpansion(this.groups[index - 1].id);
                        //         this.toggleGroupExpansion(this.groups[index].id);

                        // }
                    }, () => {
                        this.minusClicked(item, group);
                    });
                } else if (isOptional) {
                    const realItem = this.itemMap[item.id];
                    if (realItem.mgroups && realItem.mgroups.length > 0) {
                        this.handlingModifier(oi, group, this.itemMap, () => {
                        }, () => {
                            this.minusClicked(item, group);
                        }, true);

                    }
                }

            }

        } else if (this.getGroupMode(group) === 'quantity') {
            const max: number = group.max;
            if (max <= this.totalQuantity(groupIndex)) {
                console.log('max reached');
                this.toggleGroupExpansion(group.id);
                return;
            }

            this.builder.plusItem(group, item, groupIndex);
            oi = this.builder.build();
            if (!isPreselect) {
                if (this.isGroupCompleted(group)) {

                    this.handlingModifier(oi, group, this.itemMap, () => {
                        if (this.groups[groupIndex]) {
                            const currentQty = this.totalQuantity(groupIndex);
                            if (this.groups[groupIndex - 1].max === this.groups[groupIndex - 1].min) {
                                this.toggleGroupExpansion(this.groups[groupIndex - 1].id);
                                this.toggleGroupExpansion(this.groups[groupIndex].id);
                            } else if ((this.groups[groupIndex - 1].max !== this.groups[groupIndex - 1].min && currentQty === this.groups[groupIndex - 1].max
                                && this.groups[groupIndex - 1].max !== 99)) {
                                this.toggleGroupExpansion(this.groups[groupIndex - 1].id);
                                this.toggleGroupExpansion(this.groups[groupIndex].id);
                            }
                        }
                    }, () => {
                        const tmpItems: Item[] = [];
                        for (const item of oi.groups[groupIndex].items) {
                            for (let i = 0; i < item.quantity; i++) {
                                tmpItems.push(this.itemMap[item.id]);

                            }

                        }
                        for (const item of tmpItems) {
                            this.minusClicked(item, group);

                        }
                    });

                } else if (isOptional) {
                    const realItem = this.itemMap[item.id];
                    if (realItem.mgroups && realItem.mgroups.length > 0) {
                        this.handlingModifier(oi, group, this.itemMap, () => {
                        }, () => {
                            this.minusClicked(item, group);
                        }, true);

                    }
                }

            }
        }

    }

    titleGroupClicked(title: string, index: number, groupId: string, overwrite?: 1 | -1) {
        const childNode = document.getElementById('child-' + groupId + '-' + index);
        if (this.isTitleGroupExpand[groupId]) {
            if (overwrite) {
                this.isTitleGroupExpand[groupId][title] = overwrite === 1;
            } else {
                this.isTitleGroupExpand[groupId][title] = !this.isTitleGroupExpand[groupId][title];
            }
            if (childNode) {
                childNode.style.maxHeight = this.isTitleGroupExpand[groupId][title] ? childNode.scrollHeight + 'px' : '0px';
            }
        }
        // let currentStatus = this.isTitleGroupExpand[groupId][title];
        // if (this.openedTitleGroup && this.openedTitleGroup !== {}) {
        //     this.isTitleGroupExpand[groupId][title] = false;
        //     this.openedTitleGroup['childNode'].style.maxHeight = '0px';
        // }
        // if (this.openedTitleGroup && this.openedTitleGroup['title'] === title) {
        //     const needReturn = this.openedTitleGroup['groupId'] === groupId;
        //     this.openedTitleGroup = null;
        //     if (needReturn && !isOpeningGroupCell) {
        //         return;
        //     }
        // }
        // // this.isTitleGroupExpand[title] = !this.isTitleGroupExpand[title];
        // const childNode = document.getElementById('child-' + groupId + '-' + index);
        // if (this.isTitleGroupExpand[groupId][title]) {
        //     this.isTitleGroupExpand[groupId][title] = false;
        //     this.openedTitleGroup = null;
        //     childNode.style.maxHeight = '0px';
        // } else if (this.titles[groupId].length === 1) {
        //     this.isTitleGroupExpand[groupId][title] = true;
        //     childNode.style.maxHeight = childNode.scrollHeight + 'px';
        //     this.openedTitleGroup = {childNode: childNode, title: title, groupId: groupId};
        //
        // } else {
        //     this.isTitleGroupExpand[groupId][title] = true;
        //     childNode.style.maxHeight = childNode.scrollHeight + 'px';
        //     this.openedTitleGroup = {childNode: childNode, title: title, groupId: groupId};
        //
        // }
    }
    addClicked(item: Item, group: ItemGroup, detectChanges: boolean = true, isPreselect: boolean = false) {
        // to do
        // this.googleAnalyticEventTrack("add-item", item.name);
        let groupId = group.id;
        if (this.noNeedConfirm && this.noNeedConfirm[groupId]) delete this.noNeedConfirm[groupId];
        console.log('addClicked');
        if (this.isSoldout(item)) {
            console.log('item sold out, return', item);
            return;
        }
        console.log('page点击传递的参数', item, group, isPreselect);
        this.add(item, group, isPreselect);
        this.handleModifier();
        if (detectChanges) {
            this.cd.detectChanges();
        }
        console.log('build', this.builder);
        // fixed: 取消選擇後, css 要回到未選擇時的style
        // this.checkingGroupCompleted(this.getItemRealGroupIndex(item));

    }

    discard(item: Item, group: ItemGroup) {
        /* if (this.quantityMap[item.id] && this.quantityMap[item.id] > 0) {
            this.quantityMap[item.id]--;
        } */

        this.builder.minusItem(null, item, this.getGroupIndex(group));
        this.builder.build();
    }

    minusClicked(item: Item, group: ItemGroup) {
        console.log('minusClicked');
        // to do
        // this.googleAnalyticEventTrack("click", "minus-button");

        this.discard(item, group);
        // this.checkingGroupCompleted(item['groupIndex']);
        this.handleModifier();
        if (this.cd) {
            this.cd.detectChanges();
        }

    }

    public pageName(): string {
        return '/multi-step-combo';
    }

    numberToFixed(number: number): number {
        if ('HKD' === this.orderService.currency) {
            return Number(number.toFixed(1));
        } else if ('CNY' === this.orderService.currency) {
            return Number(number.toFixed(1));

        } else if ('SGD' === this.orderService.currency) {
            return Number(number.toFixed(2));

        } else {
            return Number(number.toFixed(1));

        }
    }

    matchingGroupIndex(oi: OrderItem) {
        const groups = oi.groups;
        if (groups.length !== this.builderGroupsCount && groups.length - 1 <= this.groups.length) {
            this.groupIdMap = {};
            for (let i = 0; i < groups.length; i++) {
                this.groupIdMap[groups[i].id] = i;
            }

        }
        this.builderGroupsCount = groups.length;
    }

    updateSelectedModifier() {
        const oi: OrderItem = this.builder.build();

        let modifiers: Item[] = [];
        let total = 0;
        oi.groups.forEach(group => {

            const items: Item[] = group.items;

            items.forEach(item => {
                total = this.numberToFixed(total + item.price * item.quantity);
            });

            items.filter(item => item.mgroups && item.mgroups.length > 0).forEach(item => {

                const mgroups: ItemGroup[] = item.mgroups;

                mgroups.forEach(mgroup => {

                    modifiers = modifiers.concat(mgroup.items);

                });

            });

        });

        modifiers = modifiers.filter(item => item.quantity > 0);

        modifiers.forEach(item => {
            total = this.numberToFixed(total + item.price * item.quantity);
        });

        console.log('update price');
        this.total = total;
    }

    handleModifier() {
        const oi: OrderItem = this.builder.build();
        this.currentOrderItem = oi;
        const modifiable: Item[] = this.modifierBuilder.findItems(oi);
        this.modifiable = modifiable.length > 0;
        this.matchingGroupIndex(this.currentOrderItem);
        console.log('modifiable?', this.modifiable, modifiable);

        this.updateSelectedModifier();

    }

    totalQuantity(groupIndex: number): number {
        return this.builder.getChoices(groupIndex);
    }

    isCompleted(): boolean {
        // const groups: ItemGroup[] = this.builder.groups;
        // index 0 is main group, which must has an selected item
        for (let i = 0; i < this.groups.length; i++) {
            /*
            * Suppose now there are 5 groups and they are [A, B, C, D, E] and A is the main group,
            * this.groups is an array [B, C, D, E],
            * builder.groups is an array with groups which have selected itemGroupMap, says [A, B, C, (null), (null)]
            *
            * then we check this.groups one by one (except main group)
            * if this.groups[i] is not optional
            * builder.groups[i + 1] must has an selected item
            * if not, then it is incompleted
            */
            if (!this.isGroupCompleted(this.groups[i])) {
                return false;
            }


            if (!this.isRequiredModifierSelect(i)) {
                return false;
            }
        }
        return true;

    }

    async showNotCompletedAlert() {

        const subtitle = this.t.instant('pages.set-select.select-error');
        const confirm = this.t.instant('buttons.ok');

        const alert = await this.alertController.create({
            message: subtitle,
            buttons: [confirm]
        });
        alert.present();
    }

    getBuilderGroup(group): ItemGroup {
        return this.builder.groups.find(g => {
            return (g !== null && g !== undefined && g.id === group.id);
        });
    }

    getSelectedItemNamesArr(group): Array<string> {


        const itemGroup: ItemGroup = this.getBuilderGroup(group);
        if (!itemGroup) {
            return [];
        }

        if (this.isNoNeedSelected(group)) {
            return [];
            // return this.t.instant('pages.item-select.no-need');
        }
        const selected = itemGroup.items;
        if (!selected) {
            return [];
        }
        let arr = [];
        selected.filter(item => item.quantity > 0).map(item => {
            let qtyPart = '';
            if (item.quantity > 1) {
                qtyPart = item.quantity + 'x ';
            }
            arr.push(qtyPart + item.name);
        });
        return arr;
    }

    async presentModifierPopup(store: Store, orderItem: OrderItem, group: ItemGroup, itemMap: any, page: any, handler?: any, showLastOne?: boolean) {
        // let options = {showBackdrop: true, backdropDismiss: true, cssClass: 'modal-largescreen'};
        const input = {store: store, orderitem: orderItem, group: group, itemMap: itemMap};
        if (showLastOne) {
            input['showLastOne'] = showLastOne;
        }
        const modal = await this.modalController.create({
            component: page,
            componentProps: input,
            animated: true,
            showBackdrop: true,
            backdropDismiss: true,
            cssClass: 'modal-largescreen modifier-modal'
        });
        modal.onDidDismiss().then((confirm) => {
            if (handler) {
                handler(confirm);
            }
        });
        modal.present();
    }

    hasRequiredModifier(items: Item[]): boolean {
        return this.hasModifier(items, this.itemMap, true);
    }

    getSelectedItemNamesModifier(group, i): string {
        //    if (this.builder.groups) return ;
        console.log('groupgroup', group);
        let itemGroup: ItemGroup = this.builder.groups.filter(
            _group => {
                if (_group) {
                    return _group.id === group.id;
                } else {
                    return false;
                }
            }
        )[0];

        // if (itemGroup === null) {
        //     return this.t.instant("pages.item-select.no-need");
        //     // return "不需要";
        // }

        // if (typeof itemGroup === 'undefined') {
        //     return "";
        // }

        if (!itemGroup) {
            return '';
        }
        let selected = itemGroup.items;
        if (!selected) {
            return '';
        }

        // get modifier
        let firstItem = true;
        console.log('selectedselected', selected, i);
        let output = '';
        for (let select of selected) {

            if (select.id === i.id) {
                if (!select.mgroups) continue;


                for (let mgroup of select.mgroups) {
                    if (!mgroup.items) continue;

                    if (select.id === i.id)
                        for (let item of mgroup.items) {
                            if (firstItem) {
                                output = item.name + ' x ' + item.quantity;
                                firstItem = false;

                            } else {
                                output = output + ', ' + item.name + ' x ' + item.quantity;
                            }
                        }

                    // return output;
                }
            }
        }

        return output;
    }

    hasModifier(items: Item[], itemMap: any, checkRequired: boolean = false): boolean {
        let required = false;
        for (const item of items) {
            const tmpItem = itemMap[item.id];
            if (tmpItem && tmpItem.mgroups && tmpItem.mgroups.length > 0) {
                if (checkRequired) {
                    for (const mgroup of tmpItem.mgroups) {
                        if (mgroup.min && mgroup.min > 0) {
                            required = true;
                            break;
                        }
                    }
                } else {
                    required = true;
                    break;

                }

            }
        }
        return required;
    }

    isRequiredModifierSelect(index: number): boolean {
        const oi = this.currentOrderItem ? this.currentOrderItem : this.builder.build();
        this.currentOrderItem = oi;
        const tmpIndex = index + 1;
        const group = oi.groups[tmpIndex];
        if (group && this.hasRequiredModifier(group.items)) {

            const builderItems: Item[] = group.items;
            const selected = !builderItems.some(item => {
                const builderMgroups: ItemGroup[] = item.mgroups;
                const mgroups: ItemGroup[] = this.itemMap[item.id].mgroups;

                if (mgroups) {
                    for (let i = 0; i < mgroups.length; i++) {
                        if (mgroups[i].items && mgroups[i].items.length === 0) {
                            continue;
                        }

                        if (builderMgroups) {
                            for (let b of builderMgroups) {
                                if (b.id === mgroups[i].id && mgroups[i].min > 0) {
                                    let quantity = 0;
                                    for (const t of b.items) {
                                        quantity += 1 * t.quantity;
                                    }
                                    return !(quantity >= mgroups[i].min);
                                }
                            }
                        }
                        // if (builderMgroups && builderMgroups[i] && mgroups[i].min > 0) {
                        //     let quantity = 0;
                        //     for (const t of builderMgroups[i].items) {
                        //         quantity += 1 * t.quantity;
                        //     }
                        //     return !(quantity >= mgroups[i].min);

                        // } else if ((!builderMgroups || !builderMgroups[i])) {
                        //     return mgroups[i].min > 0;
                        // }

                    }
                } else {
                    return false;
                }


            });
            return selected;

        } else {
            return true;
        }

    }

    isGroupCompleted(group: ItemGroup): boolean {
        // console.log(group);
        if (!group) {
            return true;
        }
        const index = this.getGroupIndex(group);
        const quantity = this.totalQuantity(index);
        if (group.min <= quantity || quantity === group.max) {
            return true;
        }
        if (group.optional) {
            if (group.skippable) {
                return true;
            } else {
                return index === -1;
            }
        }
        return false;
    }

    isNoNeedSelected(group: ItemGroup): boolean {
        return this.noNeedConfirm[group.id] && this.getGroupIndex(group) === -1;
    }

    getGroupIndex(group: ItemGroup): number {
        return this.builder.groups.findIndex(g => {
            // TODO: is compared by id safe?
            return (g !== null && g !== undefined && g.id === group.id);
        });
    }

    handlingModifier(oi: OrderItem, realGroup: ItemGroup, itemMap, addHandler: any, notAddHandler: any, showLastOne: boolean = false) {
        if (!oi) {
            return;
        }
        let group = oi.groups.find((g) => g.id === realGroup.id);
        group = oi.groups.find((g) => g.id === realGroup.id);
        if (this.hasRequiredModifier(group.items)) {
            this.presentModifierPopup(this.store, oi, group, itemMap, ModifierDialog, (confirm) => {
                if (confirm && confirm['data']) {
                    addHandler();

                } else {
                    notAddHandler(group);

                }
            }, showLastOne);
        } else {
            addHandler();
        }
    }

    getGroupMode(group: ItemGroup) {
        return group.repeat ? 'quantity' : 'single';
    }

    isCurrentGroupHasModifier(group: ItemGroup, map?: any): boolean {
        let index = this.groupIdMap[group.id];

        let g = this.currentOrderItem && this.currentOrderItem.groups ? this.currentOrderItem.groups[index] : null;
        if (g) {
            let has = this.hasModifier(g.items, this.itemMap);
            if (map) {
                map[g.id] = has;
            }
            return has;
        } else {
            return false;
        }
    }

    showModifierPopup(group: ItemGroup) {

        let oi = this.builder.build();
        let index = this.groupIdMap[group.id];
        let g = oi.groups[index];
        if (this.hasModifier(group.items, this.itemMap)) {

            this.presentModifierPopup(this.store, oi, g, this.itemMap, ModifierDialog, (result) => {
                this.handleModifier();
                this.cd.detectChanges();
            });

        }

    }

    private settingMultOrderManager() {
        if (this.multOrderManager.getOrderManager(this.store.id)) {
            this.orderManager = this.multOrderManager.getOrderManager(this.store.id);
        } else {
            let orderManager = new OrderManager();
            orderManager.createOrder(this.store.id, null, null, null, this.store);
            this.multOrderManager.setOrderManagerMap(orderManager, this.store.id);
            this.orderManager = this.multOrderManager.getOrderManager(this.store.id);

        }
    }


}
