import {Injectable} from '@angular/core';
import {BaseService} from '../base/base-service';
import {ConfigService} from './config.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NavController} from '@ionic/angular';
import {Subject} from 'rxjs';
import {Location} from '@angular/common';

/**
 *  Use this singleton service to maintain state, pass parameters between navigation
 * */
@Injectable({
    providedIn: 'root'
})
export class NavigationService extends BaseService {
    // TODO : keep a copy of cache in localstorage to handle refresh?
    cache: any = {};
    navigating = false;
    lastParams: any;
    poping = false;
    // private pageStack: string[] = [];
    deactivateResult: Subject<boolean> = new Subject<boolean>();
    backByLocation = false;
    isAlertingDeactive = false;
    private rootPageUrl = '';

    constructor(public configs: ConfigService, public location: Location, public router: Router, public navController: NavController) {
        super();
    }

    popPage() {
        this.poping = true;
        console.log('poping');
        // this.navController.pop().then(() => {
        //     console.log('pop done', this.poping);
        //     // this.poping = false;
        // });
        this.navController.setDirection('back', true, 'back');

        this.location.back();
    }

    popToParent(route: ActivatedRoute) {
        this.poping = true;
        this.navController.pop().then(() => {
            console.log('pop done', this.poping);
            // this.poping = false;
        });
        // this.navController.setDirection('back');
        // this.router.navigate(['../'], {relativeTo: route, replaceUrl: true}).then(() => {
        // });
    }

    popToRoot() {
        this.poping = true;
        this.navController.setDirection('root', true, 'back');
        this.navController.navigateRoot(this.getRootPageUrl()).then(() => {
            // this.poping = false;
        });
    }

    configRootUrl() {
        console.log('config root url in navigationService');
        // TODO: config the root page
        /**
         *  check params to generate root page url
         *  /store/{id}/spot/{tableNo}
         *  /store/{id}/mode/{mode}
         *  /court-store-list/{id}/mode/{mode}
         *  /court-store-list//{id}/spot/{tableNo} ?
         *  please add more cases here
         * */
        const temp = window.location.pathname;
        let rootUrl = '';
        const storeIndex = temp.indexOf('store/');
        const courtIndex = temp.indexOf('court-store-list/');
        let modeEnd = 0;
        if (storeIndex !== -1) {
            rootUrl += 'store/';
            modeEnd = temp.indexOf('/', storeIndex + 6);
            rootUrl += temp.substring(storeIndex + 6, modeEnd);
        } else if (courtIndex !== -1) {
            rootUrl += 'court-store-list/';
            modeEnd = temp.indexOf('/', courtIndex + 17);
            rootUrl += temp.substring(courtIndex + 17, modeEnd);
        }
        const spotIndex = temp.indexOf('spot/');
        if (spotIndex !== -1) {
            rootUrl += '/spot/';
            modeEnd = temp.indexOf('/', spotIndex + 5);
            if (modeEnd !== -1) {
                rootUrl += temp.substring(spotIndex + 5, modeEnd);
            } else {
                rootUrl += temp.substring(spotIndex + 5);
            }
        }
        const courtModeIndex = temp.indexOf('court/'); // court  Mode
        if (courtModeIndex !== -1) {
            rootUrl += 'court/';
            modeEnd = temp.indexOf('/', courtModeIndex + 6);
            rootUrl += temp.substring(courtModeIndex + 6, modeEnd !== -1 ? modeEnd : temp.length + 1); // court/123231   后面没 /
        }
        const historyIndex = temp.indexOf('history/'); // court  Mode
        if (historyIndex !== -1) {
            // direct history page
            rootUrl += temp;
        }
        const keyIndex = temp.indexOf('key/');
        if (keyIndex !== -1) {
            rootUrl += '/key/';
            let keyEnd = temp.indexOf('/', keyIndex + 4);
            if (keyEnd !== -1) {
                rootUrl += temp.substring(keyIndex + 4, keyEnd);
            } else {
                rootUrl += temp.substring(keyIndex + 4);
            }
        }
        const modeIndex = temp.indexOf('mode/');
        if (modeIndex !== -1) {
            rootUrl += '/mode/';
            modeEnd = temp.indexOf('/', modeIndex + 5);
            if (modeEnd !== -1) {
                rootUrl += temp.substring(modeIndex + 5, modeEnd);
            } else {
                rootUrl += temp.substring(modeIndex + 5);
            }
        }
        this.setRootPageUrl(rootUrl);
        console.log('set root Url in navigation service', rootUrl);
        if (!(<any>document.getElementById('baseHref')).href || (<any>document.getElementById('baseHref')).href === '/') {
            (<any>document.getElementById('baseHref')).href = rootUrl;
        }
    }

    setRootPageUrl(url: string) {
        if (!url) {
            console.log('wrong input root url, reject', url);
            return;
        }
        this.rootPageUrl = url;
        this.configs.setLocal('rootPage', this.rootPageUrl);
        console.log('set root page ', url);
    }

    getRootPageUrl(ignoreCache = false): string {
        if (ignoreCache && !this.rootPageUrl) {
            this.rootPageUrl = this.configs.getLocal('rootPage');
        }
        return this.rootPageUrl;
    }

    setNavigationParams(params: any) {
        this.cache = params;
    }

    getNavigationParams() {
        return this.cache;
    }
}
