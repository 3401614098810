<ion-header>
        <div class="list-header">
                <span>{{title}}</span>
                <ion-button fill="clear" (click)="dismissClicked()">
                    <ion-icon slot="icon-only" name="md-close" color="dark"></ion-icon>
                </ion-button>
            </div>
</ion-header>
<ion-content class="content" (click)="dismissClicked()">

    <div class="content-box" (click)="$event.stopPropagation()">

        <ion-list class="couponBS-wrapper animated fadeInRights">

            <ion-item *ngFor="let item of items; let i = index" (click)="itemClicked(i)" class="item-box" >
                <div class="sold-out-box AQA-soldOut" *ngIf="isSoldout(item)" item-content>
                        <span>{{"pages.item-grid.soldout" | translate}}</span>
                    </div>
                <!--<ion-radio></ion-radio>-->

                <ion-label class="item-info">
                    <h2 class="itemName-text">{{item.name}}</h2>
                    <p class="itemDesc-text">{{getModifierString(item)}}</p>
                    <span class="itemPrice-text">{{orderManager?.store?.currencySign}} {{item.price | number : '1.0-2'}}</span>
                </ion-label>

                <ion-thumbnail *ngIf="item.images && item.images['default']" slot="end">
                    <img src="{{item.getImage('default', 100)}}">
                </ion-thumbnail>

                <ion-checkbox color="success" slot="end" [checked]="selectedIndex === i"></ion-checkbox>
            </ion-item>

        </ion-list>

    </div>
</ion-content>
<ion-footer>
        <div class="btn-box">
                <ion-button expand="block" class="confirm-btn key-btn-box AQA-key-button" [class.confirmed]="selectedIndex >= 0" (click)="okClicked()">
                    {{"pages.variation.confirm" | translate}}
                </ion-button>
            </div>
</ion-footer>
