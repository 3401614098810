import {Ad, Store} from 'aigens-ng-core';
import {MobileBasePage} from '../../core/base/mobile-base-page';
import {Component, Injector, OnInit} from '@angular/core';
import {NavParams} from '@ionic/angular';
import {RouteTable} from 'src/app/core/route-table';

/**
 * Generated class for the DineIn page.
 *
 * See http://ionicframework.com/docs/components/#navigation for more info
 * on Ionic pages and navigation.
 */

@Component({
    selector: 'page-popup',
    templateUrl: 'popup.html',
    styleUrls: ['popup.scss']
})
export class PopupDialog extends MobileBasePage implements OnInit {

    ad: Ad;
    store: Store;

    constructor(private injector: Injector, public navParams: NavParams) {
        super(injector);

        this.ad = this.getNavParams('ad');
        this.store = this.getNavParams('store');
        console.log('nav', navParams, this.store);
    }

    ngOnInit() {
        console.log('ngOnInit Popup');
    }

    getImage() {
        return this.ad.getImage('default', 500, false);
    }

    dismissClicked() {

        this.modalController.dismiss(null);

        // this.navController.pop({animate: false});

        console.log('dismiss?');

    }

    adClicked() {
        console.log('ad clicked');
        this.modalController.dismiss(this.ad['links']);
        // this.pushByUrl()
        if (this.ad['links']) return;
        if (this.memberService.getMember()) {
            this.pushByUrl(RouteTable['MemberRewardPage']);
        } else {
            this.pushByUrl(RouteTable['LoginPage'], {
                store: this.store,
                fromPage: 'ByodSplashPage', logincb: () => {
                    // this.next();
                }
            });
        }
    }


}
