var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ModifierBuilder, OrderItem } from 'aigens-ng-core';
import { ChangeDetectorRef, Injector, OnDestroy } from '@angular/core';
import { MobileBasePage } from '../../../core/base/mobile-base-page';
import { NavParams } from '@ionic/angular';
var ModifierItemSelectPageModal = /** @class */ (function (_super) {
    __extends(ModifierItemSelectPageModal, _super);
    function ModifierItemSelectPageModal(injector, cd, navParams) {
        var _this = _super.call(this, injector) || this;
        _this.injector = injector;
        _this.cd = cd;
        _this.navParams = navParams;
        _this.confirm = false;
        _this.mmap = {};
        _this.absentItemsId = [];
        _this.confirmHandling = false;
        // this.originGroups = this.getNavParams("groups");
        /*if(this.getNavParams("oneTime")){
            this.navController.pop({animate:false});

        }*/
        console.log('ModifierItemSelectPage');
        // this.orderItem = this.getNavParams('orderitem');
        // this.store = this.getNavParams('store');
        _this.orderItem = _this.navParams.get('orderitem');
        _this.store = _this.navParams.get('store');
        if (!_this.store) {
            _this.store = _this.orderManager.store;
        }
        //
        // if (!this.orderItem || !this.store) {
        //     //TODO: Guard?
        //     this.router.navigate([this.navigationService.getRootPageUrl()]);
        // }
        _this.builder = new ModifierBuilder(_this.store, _this.orderItem);
        var items = _this.builder.findItems(_this.orderItem);
        _this.modifiableItems = items;
        _this.modifiableItems.forEach(function (item) {
            item.mgroups.forEach(function (mgroup) {
                // let tmp = mgroup.items.filter(item => item.absentId != null && item.absentId != "")
                // tmp.forEach((item)=>{
                //     this.absentItemsId.push(item.absentId);
                // })
                mgroup.items.forEach(function (mitem) {
                    if (mitem.absentId != null && mitem.absentId !== '') {
                        _this.absentItemsId.push(mitem.absentId);
                    }
                    if (mitem.mgroup && _this.has(item, mgroup, mitem)) {
                        _this.mmap[mitem.mgroup] = mitem;
                    }
                });
                mgroup.items.forEach(function (mitem) {
                    if (mgroup.defaultId && mitem.id === mgroup.defaultId) {
                        _this.itemClicked(item, mgroup, mitem, true);
                    }
                });
            });
        });
        _this.tmp = _this.modifiableItems;
        console.log(_this.modifiableItems);
        return _this;
    }
    ModifierItemSelectPageModal.prototype.getPageUrl = function () {
        return 'modifier-item-select';
    };
    ModifierItemSelectPageModal.prototype.pageName = function () {
        return '/modifier-item-select';
    };
    ModifierItemSelectPageModal.prototype.closeModal = function () {
        this.modalController.dismiss();
        // debugger
        // if ((this.navigationService.lastParams && this.navigationService.lastParams['orderitem']) || this.navigationService.poping) {
        //    this.modalController.dismiss();
        // } else {
        //     // this.navController.pop().then(data => {
        //     //     console.log('pop ok', data);
        //     // }, error => {
        //     //     console.log('cannot pop', error);
        //     const t = this.navigationService.getRootPageUrl();
        //     console.log('home guard', t);
        //     if (t) {
        //         // TODO: not allowed to enter target root. go to parent route?
        //         this.navController.setDirection('root');
        //         this.router.navigate([this.navigationService.getRootPageUrl()]);
        //     }
        // }
    };
    ModifierItemSelectPageModal.prototype.getSelectRules = function (igroup) {
        if (!igroup || isNaN(igroup.max) || isNaN(igroup.min)) {
            return '';
        }
        var min = Number(igroup.min);
        var max = Number(igroup.max);
        if (min === max && min === 0) {
            return '';
        }
        // 請選擇{{count}}項
        if (min === max) {
            return this.t.instant('pages.item-select.select-items', { count: min });
        }
        // 加配項目
        if (min === 0 && max === 99) {
            return this.t.instant('pages.item-select.optional');
        }
        // 請選最多max項
        if (min === 0 && max !== 99) {
            return this.t.instant('pages.item-select.chooseAtMost', { max: max });
        }
        // 請選至少{{min}}項
        if (min !== 0 && max === 99) {
            return this.t.instant('pages.item-select.chooseUp', { min: min });
        }
        // 請選{{min}} - {{max}}項
        if (min < max && max !== 99) {
            return this.t.instant('pages.item-select.chooseTo', { min: min, max: max });
        }
        return '';
    };
    ModifierItemSelectPageModal.prototype.isMGroupSelected = function (item, igroup) {
        if (!item || !igroup || isNaN(igroup.max) || isNaN(igroup.min)) {
            return false;
        }
        var min = Number(igroup.min);
        var max = Number(igroup.max);
        var items = igroup.items || [];
        var isSelect = false;
        var quantities = 0;
        for (var _i = 0, items_1 = items; _i < items_1.length; _i++) {
            var tmpItem = items_1[_i];
            quantities += this.builder.getQuantity(item, igroup, tmpItem);
        }
        if (min > 0) {
            isSelect = quantities >= igroup.min;
        }
        else if (quantities > 0) { /// min = 0
            isSelect = true;
        }
        return isSelect;
    };
    ModifierItemSelectPageModal.prototype.itemClicked = function (item, group, mItem, isPreselect) {
        if (isPreselect === void 0) { isPreselect = false; }
        var max = group.max;
        var contain = this.has(item, group, mItem);
        if (contain && isPreselect) {
            return;
        }
        if (mItem.baseQty > 0) {
            var count = this.builder.getQuantity(item, group, mItem);
            if (count < mItem.max) {
                this.builder.plus(item, group, mItem);
            }
        }
        else if (group.repeat) {
            if (this.canAdd(item, group, mItem.mgroup ? mItem : null)) {
                this.builder.plus(item, group, mItem);
            }
        }
        else {
            if (max === 1) {
                this.builder.clearForItem(item, group);
                if (!contain) {
                    this.builder.setMod(item, group, mItem, 1);
                }
            }
            else if (max > 1) {
                var q = void 0;
                if (contain) {
                    q = 0;
                    if (mItem.mgroup) {
                        this.mmap[mItem.mgroup] = null;
                    }
                }
                else {
                    if (this.canAdd(item, group, mItem.mgroup ? mItem : null)) {
                        q = 1;
                    }
                    else {
                        q = 0;
                    }
                }
                if (mItem.mgroup && q === 1) {
                    this.clearSameMGroup(item, group, mItem);
                }
                this.builder.setMod(item, group, mItem, q);
            }
        }
        if (!isPreselect) {
            this.fix();
        }
    };
    ModifierItemSelectPageModal.prototype.clearSameMGroup = function (item, group, mItem) {
        var i = this.mmap[mItem.mgroup];
        if (i) {
            this.builder.setMod(item, group, i, 0);
        }
        this.mmap[mItem.mgroup] = mItem;
    };
    ModifierItemSelectPageModal.prototype.fix = function () {
        this.cd.detectChanges();
        // this.push(ModifierItemSelectPage, { store: this.store, orderitem: this.builder.build(),oneTime:true },{animate:false});
        // this.modifiableItems = this.tmp;
    };
    ModifierItemSelectPageModal.prototype.ngOnDestroy = function () {
        // this.handleCallback(this.confirm)
        this.confirmHandling = false;
    };
    ModifierItemSelectPageModal.prototype.confirmClicked = function () {
        if (this.confirmHandling) {
            console.log('is processing, return');
            return;
        }
        else {
            this.confirmHandling = true;
        }
        var orderManager = this.multOrderManager.enableMutlOrder ? this.multOrderManager.getOrderManager(this.store.id) : this.orderManager;
        if (OrderItem.isSet(this.orderItem) || this.orderItem.quantity === 1) {
            var oi = this.builder.build();
            orderManager.refreshOrderItem(oi);
        }
        else {
            var duplicated = orderManager.duplicateOrderItem(this.orderItem);
            duplicated.quantity--;
            var oi = this.builder.build();
            oi.quantity = 1;
            orderManager.refreshOrderItem(oi);
        }
        this.confirm = true;
        console.log('on pop');
        // this.navController.setDirection('back');
        // this.router.navigate(['../'], {relativeTo: this.route, replaceUrl: true}).then(() => {
        // });
        // this.navigationService.popPage();
        this.modalController.dismiss('confirm');
        console.log('pop done');
    };
    ModifierItemSelectPageModal.prototype.has = function (item, group, mItem) {
        // console.log(mItem.name + ":" + this.builder.getQuantity(item, group, mItem));
        return this.builder.getQuantity(item, group, mItem) > 0;
    };
    ModifierItemSelectPageModal.prototype.canAdd = function (item, group, mItem) {
        var max = group.max;
        var quantities = 0; // this.builder.getSelectedModifierCount(item);
        for (var _i = 0, _a = group.items; _i < _a.length; _i++) {
            var tmpItem = _a[_i];
            quantities += this.builder.getQuantity(item, group, tmpItem);
        }
        if (mItem && this.mmap[mItem.mgroup] && mItem.id !== this.mmap[mItem.mgroup].id) {
            return true;
        }
        return max > quantities;
    };
    ModifierItemSelectPageModal.prototype.minusClicked = function (item, group, mItem) {
        var count = this.builder.getQuantity(item, group, mItem);
        if (count > mItem.min) {
            this.builder.minus(item, group, mItem);
            this.fix();
        }
    };
    ModifierItemSelectPageModal.prototype.backButtonClick = function () {
        this.handleCallback(this.confirm);
        _super.prototype.backButtonClick.call(this);
    };
    ModifierItemSelectPageModal.prototype.isCompleted = function () {
        var completed = true;
        for (var _i = 0, _a = this.modifiableItems; _i < _a.length; _i++) {
            var item = _a[_i];
            var mgroups = item.mgroups;
            if (mgroups) {
                for (var i = 0; i < mgroups.length; i++) {
                    var quantities = 0;
                    for (var _b = 0, _c = mgroups[i].items; _b < _c.length; _b++) {
                        var tmpItem = _c[_b];
                        quantities += this.builder.getQuantity(item, mgroups[i], tmpItem);
                    }
                    if (mgroups[i].min > 0) {
                        completed = quantities >= mgroups[i].min;
                    }
                    else {
                        completed = true;
                    }
                    if (!completed) {
                        break;
                    }
                }
                if (!completed) {
                    break;
                }
            }
        }
        return completed;
    };
    return ModifierItemSelectPageModal;
}(MobileBasePage));
export { ModifierItemSelectPageModal };
