var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, OnInit } from '@angular/core';
import { MobileBasePage } from '../../core/base/mobile-base-page';
import { NavParams } from '@ionic/angular';
import { isAvailableOffer, isAvailableReward } from '../../shared/utilities/checkFormatTool';
var OfferListDialog = /** @class */ (function (_super) {
    __extends(OfferListDialog, _super);
    function OfferListDialog(injector, navParams) {
        var _this = _super.call(this, injector) || this;
        _this.injector = injector;
        _this.navParams = navParams;
        _this.offers = [];
        _this.isBounce = false;
        _this.offers = _this.navParams.get('offers');
        console.log(_this.offers);
        return _this;
    }
    OfferListDialog.prototype.ngOnInit = function () {
        console.log('ngOnInit VariationPage');
    };
    OfferListDialog.prototype.dismissClicked = function () {
        this.modalController.dismiss(null);
    };
    OfferListDialog.prototype.bounce = function () {
        var _this = this;
        this.isBounce = true;
        setTimeout(function () {
            _this.isBounce = false;
        }, 1000);
    };
    OfferListDialog.prototype.isAvailableReward = function (offer) {
        return isAvailableReward(offer.reward);
    };
    OfferListDialog.prototype.clickReward = function (offer) {
        if (isAvailableOffer(offer)) {
            this.modalController.dismiss(offer);
            this.bounce();
        }
    };
    return OfferListDialog;
}(MobileBasePage));
export { OfferListDialog };
